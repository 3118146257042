import React from 'react'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'

import MenuBar from './menubar/menubar'
import './layout.scss'
import './headline.scss'

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        <Helmet
          title={data.site.siteMetadata.title}
          meta={[{ name: 'description', content: 'My fav blog' }]}
        >
          <html lang="en" />
        </Helmet>
        <MenuBar />
        {/* <img src="../../images/joehughes.png" /> */}
        {/* <Header siteTitle={data.site.siteMetadata.title} /> */}
        <div className="headline">
          <span className="welcome-to">Welcome To</span>
          <span className="joe-hughes">JoeHughes.dev</span>
        </div>

        {/* blog posts */}
        <div
          style={{
            margin: '0 auto',
            // padding: '0px 1.0875rem 1.45rem',
            // paddingTop: 0,
          }}
        >
          {children}
        </div>
      </>
    )}
  />
)

export default Layout
