import React from 'react'
import './menubar.css'

const MenuBar = () => {
  return (
    <div className="MenuBar">
      <a href="/index.html">Home</a>
    </div>
  )
}

export default MenuBar
